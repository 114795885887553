import axios from '@/plugins/axios';

export default {
  getOrganisationNotificationGroups(config = {}) {
    return axios.get('/v1/organisation/notification-groups', config);
  },
  createNotificationGroup(data, config = {}) {
    return axios.post('/v1/organisation/notification-groups', data, config);
  },
  getNotificationGroup(notificationGroupId, config = {}) {
    return axios.get(`/v1/organisation/notification-groups/${notificationGroupId}`, config);
  },
  updateNotificationGroup(notificationGroupId, data, config = {}) {
    return axios.put(`/v1/organisation/notification-groups/${notificationGroupId}`, data, config);
  },
  deleteNotificationGroup(notificationGroupId, config = {}) {
    return axios.delete(`/v1/organisation/notification-groups/${notificationGroupId}`, config);
  },
};
