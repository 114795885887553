<template>
  <div>
    <page-heading :heading="$t('notification_groups')">
      <template v-slot:actions>
        <app-button :label="$t('create')" @click="openCreateModal()"></app-button>
      </template>
    </page-heading>

    <form-open class="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-10" @submit="filter">
      <form-text
        :label="$t('search')"
        :required="false"
        :show-validation-label="false"
        icon="search"
        input-id="search"
        v-model="query.filter.name"
      />
      <app-button class="hidden"/>
    </form-open>

    <loading-wrapper>
      <app-table :items="items">
        <template
          v-slot:headings
        >
          <app-th :heading="$t('id')"/>
          <app-th-sortable :label="$t('model.notification_group.name')" name="name" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('model.notification_group.users')"/>
          <app-th :heading="$t('model.notification_group.default')"/>
          <app-th/>
        </template>

        <template
          v-slot:row="{ item }"
        >
          <app-td>
            <a href="#" class="link font-bold" @click.prevent="openEditModal(item.id)">{{ item.id }}</a>
          </app-td>
          <app-td>
            <a href="#" @click.prevent="openEditModal(item.id)" class="link">{{ item.name }}</a>
          </app-td>
          <app-td class="space-x-2">
            <badge
              :key="`item-${item.id}-user-${user.id}`"
              :label="user.name"
              :title="!user.email ? $t('user_no_email') : ''"
              theme="highlight"
              v-for="user in item.users.slice(0, showMaxUsers)"
            >
              <app-svg class="ml-1 w-4 text-red-400" svg="exclamation-circle" v-if="!user.email"/>
            </badge>
            <span class="font-semibold" v-if="item.users.length > showMaxUsers">{{ $t('and_x_more', { x : item.users.length - showMaxUsers }) }}</span>
          </app-td>
          <app-td>
            <app-svg
              :class="{
                'text-gray-500': !item.default,
                'text-app-orange': item.default,
              }"
              :svg="!item.default ? 'x-circle' : 'check-circle'"
              class="h-4"
            />
          </app-td>
          <app-td>
            <app-options>
              <app-option-edit @click="openEditModal(item.id)"/>
              <app-option-delete @click="openDeleteModal(item.id)"/>
            </app-options>
          </app-td>
        </template>
      </app-table>
      <pagination
        :meta="meta"
        :page="query.page"
        v-if="meta"
        @pageUpdated="updatePage"
      />
    </loading-wrapper>

    <slideover :active="modals.createEdit" @close="closeModal('createEdit')" @submit="submit"
      :title="modelId ? $t('edit') : $t('create')"
    >
      <debug>{{ model }}</debug>
      <form-text :form-id="formId" input-id="name" form-error-id="name" :label="$t('model.notification_group.name')" v-model="model.name"/>
      <form-select
        :form-id="formId"
        :label="$t('model.notification_group.users')"
        :multiple="true"
        :options="data.users"
        form-error-id="users"
        input-id="users"
        v-model="model.users"
      />
      <form-check :form-id="formId" input-id="default" form-error-id="default" :label="$t('default')" :help="$t('default_notification_group')" true-value="1" false-value="0" v-model="model.default" />

      <template v-slot:buttons>
        <app-button
          :label="$t(modelId ? 'update' : 'create')"
          :disabled="loading"
          :loading="loading"
        />
      </template>
    </slideover>

    <modal-delete :active="modals.delete" @close="closeModal('delete')" @delete="deleteModel(modelId)"
      :title="$t('delete')"
      :text="$t('delete_thing_text', { thing: model.name })"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ApiNotificationGroupService from '@/services/api/notification_group';
import ApiUserService from '@/services/api/user';
import Common from '@/mixins/common';

export default {
  data() {
    return {
      data: {
        users: [],
      },
      formId: 'createEdit',
      items: [],
      meta: {},
      modals: {
        createEdit: false,
        delete: false,
      },
      model: this.emptyModel(),
      modelId: false,
      query: {
        filter: {},
        page: 1,
        sort: [
          'name',
        ],
      },
      showMaxUsers: 4,
    };
  },
  methods: {
    emptyModel() {
      return {
        users: [],
      };
    },
    deleteModel(modelId) {
      ApiNotificationGroupService.deleteNotificationGroup(modelId).then(() => {
        this.closeModals();
        this.getListings();
      }).catch(() => {});
    },
    filter() {
      this.query.page = 1;
      this.getListings();
    },
    getListings() {
      ApiNotificationGroupService.getOrganisationNotificationGroups({
        params: {
          ...this.query,
          include: [
            'users',
          ],
        },
      }).then((response) => {
        const { data, meta } = response.data;

        this.items = data;
        this.meta = meta;
      }).catch(() => {});
    },
    getAndSetModel(modelId) {
      return ApiNotificationGroupService.getNotificationGroup(modelId, {
        params: {
          include: [
            'users',
          ],
        },
      }).then((response) => {
        const { data } = response.data;

        this.modelId = modelId;
        this.model = this.resourceToModel(data);
      }).catch(() => {});
    },
    modelToRequest() {
      return this.model;
    },
    openCreateModal() {
      this.modelId = null;
      this.model = this.emptyModel();

      this.openModal('createEdit');
    },
    openDeleteModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('delete');
      });
    },
    openEditModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('createEdit');
      });
    },
    submit() {
      if (this.modelId) {
        ApiNotificationGroupService.updateNotificationGroup(this.modelId, this.modelToRequest(), {
          formId: this.formId,
          showMessage: true,
        }).then(() => {
          this.getListings();
          this.closeModals();
        }).catch(() => {});
      } else {
        ApiNotificationGroupService.createNotificationGroup(this.modelToRequest(), {
          formId: this.formId,
          showMessage: true,
        }).then((response) => {
          const { data } = response.data;

          this.modelId = data.id;
          this.getListings();
          this.closeModals();
        }).catch(() => {});
      }
    },
    resourceToModel(data) {
      return {
        name: data.name,
        users: _.map(data.users, 'id'),
        default: !data.default ? '0' : '1',
      };
    },
    routeQueryUpdated() {
      this.getListings();
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    ApiUserService.getOrganisationUsers({
      params: {
        per_page: -1,
        sort: 'last_name',
      },
    }).then((response) => {
      const { data } = response.data;

      this.data.users = this.toSelectOptions(data);
    }).catch(() => {});

    this.getListings();
  },
};
</script>
