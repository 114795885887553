import axios from '@/plugins/axios';

export default {
  getOrganisationUsers(params = {}) {
    return axios.get('/v1/organisation/users', params);
  },
  createOrganisationUser(data, config = {}) {
    return axios.post('/v1/organisation/users', data, config);
  },
  getOrganisationUser(userId, config = {}) {
    return axios.get(`/v1/organisation/users/${userId}`, config);
  },
  updateOrganisationUser(userId, data, config = {}) {
    return axios.put(`/v1/organisation/users/${userId}`, data, config);
  },
  deleteOrganisationUser(userId, config = {}) {
    return axios.delete(`/v1/organisation/users/${userId}`, config);
  },
  logoutUser(userId, config = {}) {
    return axios.post(`/v1/organisation/users/${userId}/logout`, config);
  },
};
